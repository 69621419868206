import "../styles/main.scss";
import "babel-polyfill";

import React from "react";
import PropTypes from "prop-types";
import Header from "./common/Header";
import Footer from "./common/Footer";
import LazyLoad from 'react-lazyload';

const DefaultLayout = ({ pageId, children }) => {
  return (
    <div>
      <main id={pageId}>
        <Header />
        {children}
      </main>
      <LazyLoad height={400} offset={100}>
        <Footer />
      </LazyLoad>
    </div>
  );
};

DefaultLayout.propTypes = {
  pageId: PropTypes.string,
  children: PropTypes.array.isRequired
};

export default DefaultLayout;
